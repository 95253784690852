@tailwind base;
@tailwind components;

.inp-border-primary {
  @apply border-gray-300 focus:ring-primary-500 focus:border-primary-500;
}

.inp-border-red {
  @apply border-red-300 focus:ring-red-500 focus:border-red-500;
}

@tailwind utilities;